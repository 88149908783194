.input2 {
  background-color: blue;
  width: 100% !important;
  border: none !important;
  font-size: 18px !important;
  font-family: inherit !important;
}

.phoneButton {
  border: none !important;
  border-right: 1px solid #cacaca !important;
}

.img-fluid{
  width: 100%;
}