#nprogress .bar {
  height: 4px;
  background: #aa000f;
  box-shadow: none;
  border: none;
  outline: none;
}
#nprogress .spinner-icon {
  display: none;
}
#nprogress .peg {
  box-shadow: none;
}
