@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: Lato;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoMedium";
  src: local("LatoMedium"), url("./fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  src: local("LatoBold"), url("./fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  src: local("LatoLight"), url("./fonts/Lato-Light.ttf") format("truetype");
}
